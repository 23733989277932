import { sendRequestBase, getParamsBase, tokenType } from './Common';

export async function updateAction(payload) {
  let searchParams = new URLSearchParams(payload);

  const endpoint = `/update/${searchParams.toString()}`;
  const params = await getParams(null, 'GET', tokenType.ACCESS);

  return sendRequest(endpoint, params);
}

async function getParams(body, verb, tokenTypeToAdd = tokenType.ACCESS) {
  return getParamsBase(body, verb, tokenTypeToAdd);
}

async function sendRequest(endpoint, params) {
  const apiURL = process.env.VUE_APP_HP_API_URL;
  const url = `${apiURL}${endpoint}`;

  return sendRequestBase(url, params);
}
