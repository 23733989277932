export async function getRandomGiphy(keyword) {
  const url = new URL('https://api.giphy.com/v1/gifs/translate');

  const options = {
    api_key: process.env.VUE_APP_GIPHY_API_KEY,
    s: keyword,
    weirdness: 0,
  };

  url.search = new URLSearchParams(options).toString();

  return fetch(url).then((response) => {
    return response.json();
  });
}
